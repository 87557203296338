<template>
  <div class="error">
    <div class="container">
      <div class="errorBox">
        <div class="left">
          <div class="state">404</div>
          <div class="title">{{$t('Home.Msg.FindErr')}}</div>
          <div class="btn">
            <div class="backHome" @click="$router.push('/')">
              <span>{{$t('Common.Button.BackHome')}}</span>
            </div>
            <div class="feedback">
              <span>{{$t('Common.Button.FeedbackProblems')}}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <img :src="require('assets/img/404/404.jpg')" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "common/empty/Empty";
export default {
  data() {
    return {};
  },
  created() {},
  components: {
    Empty,
  },
};
</script>
<style lang='scss' scoped>
.error {
  background-color: white;
  .errorBox {
    padding: 6rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .right {
      margin-left: 1rem;
      flex: none;
      img {
        vertical-align: bottom;
      }
    }

    .left {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .state {
        font-weight: 800;
        font-size: 5rem;
        color: #bcc0cb;
        margin-bottom:1rem;
      }
      .title {
        color: #5e5e5e;
        font-size: 1.25rem;
        font-weight: 600;
        // letter-spacing: 1px;
      }

      @mixin baseBtn {
        color: white;
        height: 50px;
        line-height: 50px;
        padding: 0 1.5rem;
        border-radius: 25px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .btn {
        margin:1.5rem 0;
        display: flex;
        font-size: 0.875rem;
        .backHome {
            margin-right:1rem;
          background-color: #1084ff;
          @include baseBtn;
        }

        .feedback {
          background-color: #61ca2f;
          @include baseBtn;
        }
      }
    }
  }
}
</style>